import * as types from "../constants/ActionTypes";

const initialState = {
	isLoading: true,
	isReady: false,
};

export default function appAuth(state = initialState, action: any) {
	switch (action.type) {
		case types.APP_IS_LOADING: {
			return { ...state, isLoading: action.payload };
		}
		case types.APP_IS_READY: {
			return { ...state, isReady: action.payload };
		}
		default:
			return state;
	}
}
