import * as types from '../constants/ActionTypes'

const initialState = {
  user: {
    kerberos: '',
    first_name: '',
    last_name: '',
    display_name: '',
    email: ''
  },
  picture: '',
  roles: {
    ASSIGN: false,
    IDASSIGN: false,
    IDSEARCH: false,
    QUICK_ADMIT: false,
    RUN_MIT_ID_ADMIN_REPORTS: false,
    SEARCH: false,
    UPDATE_MITID_RECORDS_LEVEL_1: false,
    UPDATE_MITID_RECORDS_LEVEL_2: false,
    VIEW_FULL_DOB_IN_MITID_SEARCH: false
  }
}

export default function app (state = initialState, action: any) {
  switch (action.type) {
    case types.CHANGE_USER: {
      return { ...state, user: action.payload }
    }
    case types.CHANGE_PICTURE: {
      return { ...state, picture: action.payload }
    }
    case types.CHANGE_ROLES: {
      // I am forcing RUN_MIT_ID_ADMIN_REPORTS to FALSE while it has not been developed
      return {
        ...state,
        roles: action.payload
      }
    }
    default:
      return state
  }
}
