import { store } from '../../redux/store/store'
import authProvider from '../../services/AuthProviderLocal'
// import appConfig from "../../api-paths-config";
import apiConfig from '../api-config'
export const globalStore = store

class BaseController {
  readonly apiHost: string
  readonly apiPath: string
  readonly apiPathDigitalId: string
  private _abortController: AbortController
  fetchController: any

  constructor () {

    let isGlobalEnabled = localStorage.getItem('isGlobalEnabled') === 'true' ?? false;
    let configKey = (isGlobalEnabled ? 'global': 'regional') as keyof typeof apiConfig;

    this.apiHost = apiConfig[configKey].apiHost ?? ''
    this.apiPathDigitalId = apiConfig[configKey].paths.digitalId ?? ''
    this.apiPath = apiConfig[configKey].paths.apiPath ?? ''

    this._abortController = new AbortController()
    this.fetchController = {}
  }

  async getHeaders (method: string, file?: boolean, fileName?: any, contentType?: string): Promise<Headers> {
    let token = await authProvider.getToken()
    token = token

    const headers: Headers = new Headers({
      Authorization: `Bearer ${token}`
    })
    if (file) {
      headers.append('Content-Type', contentType || 'binary/octet-stream')
      if (!contentType) {
        headers.append('Content-Filename', fileName)
      }
    } else {
      headers.append('Content-Type', contentType || 'application/json')
      
    }

    return headers
  }

  async useFetch (method: string, fetchKey: string, url: string, body?: any, file?: any, fileName?: string, contentType?: string): Promise<any> {
    if (fetchKey && this.fetchController[fetchKey]) {
      this.fetchController[fetchKey].abort()
    }

    this.fetchController[fetchKey] = new AbortController()
    const { signal } = this.fetchController[fetchKey]

    const options: any = {
      method,
      headers: await this.getHeaders(method, file, fileName, contentType),
      signal
    }
    if (body) {
      options.body = file ? body : JSON.stringify(body)
    }

    const response = await fetch(url, options)

    // clear out controller
    this.fetchController[fetchKey] = null

    return response
  }

  fetchPicture = async (baseUrl: string, url: string, query?: any): Promise<any> => {
    if (baseUrl === '/mock') {
      url = url + '.png'
    }

    url = baseUrl + url

    let queryString = ''

    if (query) {
      const esc = encodeURIComponent
      queryString = '?' + Object.keys(query)
        .map(k => esc(k) + '=' + esc(query[k]))
        .join('&')
    }

    try {
      const response = await fetch(url + queryString, await this.requestHeaderGet())

      if (response.status === 200) {
        const data = await response.blob()

        return URL.createObjectURL(data)
      } else {
        return null
      }
    } catch (e) {
      console.log('ERROR: ' + e)
    }
  }

  requestHeaderGet = async (): Promise<RequestInit> => {
    this._abortController = new AbortController()
    const signal = this._abortController.signal
    const options: RequestInit = {
      method: 'GET',
      headers: await this.getHeaders('GET'),
      signal
    }
    return options
  }
}

export default BaseController
