import * as types from "../constants/ActionTypes";

//Actions
export function setIsLoading(response: any) {
	return {
		type: types.APP_IS_LOADING,
		payload: response,
	};
}

export function setIsReady(response: any) {
	return {
		type: types.APP_IS_READY,
		payload: response,
	};
}

