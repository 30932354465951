
import { Provider } from 'react-redux'
import { store } from './redux/store/store'
import React, { FC, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import { Initialization } from '@mit/aws-react'
import Logout from './components/Nav/Logout'
import { useAppInitializer } from './services/initializer'
import appConfig from './app-config-global'
import './scss/main.scss'

export const globalStore = store

const WrappedOKTAApp: FC = () => {
  const { initializeApp } = useAppInitializer()
  let isLoading = store.getState().appAuth.isLoading

  const AppLazyLoad = React.lazy(async () => await import('./App').then(module => ({
    default: module.default
  })))


  return (
    <React.StrictMode>
      <Switch>
        <Route exact path="/logout" component={Logout} />
        <Route path='/'>
          <Initialization
            App={AppLazyLoad}
            appInitializer={initializeApp}
            appConfigData={appConfig}
            loaderProps={
              {
                contactEmail: 'help@mit.edu',
                name: 'MIT ID Search',
                isLoading: isLoading,
                type: 'Default',
                backgroundColor: '#20447D',
              }
            }
          />
        </Route>
      </Switch>
    </React.StrictMode>
  )
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={globalStore}>
      <WrappedOKTAApp />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
