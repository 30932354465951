import * as types from '../constants/ActionTypes'
import { ProfileModel } from '../../api/controller/models/ProfileModel'

// Actions
export function userChange (response: any) {
  return {
    type: types.CHANGE_USER,
    payload: response
  }
}

export function pictureChange (response: any) {
  return {
    type: types.CHANGE_PICTURE,
    payload: response
  }
}

export function rolesChange (response: any) {
  return {
    type: types.CHANGE_ROLES,
    payload: response
  }
}

// Dispatches
export function changeUser (user: ProfileModel) {
  return (dispatch: any) => {
    dispatch(userChange(user))
  }
}

export function changePicture (picture: string) {
  return (dispatch: any) => {
    dispatch(pictureChange(picture))
  }
}

export function changeRoles (roles: any) {
  return (dispatch: any) => {
    dispatch(rolesChange(roles))
  }
}
