import { appInitialized } from '@mit/aws-react'
import { useState, useEffect } from 'react';
import ProfileController from '../api/controller/ProfileController'
import MitidController from '../api/controller/MitidController'
import { store } from "../redux/store/store";
import * as appStore from "../redux/actions/app";
import * as authStore from "../redux/actions/app_auth";

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const [initializeApi, setInitializeApi] = useState(false)
  const profileController = new ProfileController()
  const mitidController = new MitidController()
  
  useEffect(() => {
    appInitialized({
      isLoading: true,
      hasAccess: false,
      hasError: false,
    });
  }, []);

  const initializeApp = async (): Promise<void> => {

    const auth = await mitidController.initialize()
    .catch((error: any) => {})

    if (auth && auth.FunctionAuthentication) {
        store.dispatch(appStore.changeRoles(auth.FunctionAuthentication));
    }

    await profileController.getProfile().then((data: any) => {
      if (data) {
        store.dispatch(appStore.changeUser(data));
      }
    })

    await profileController.getPicture().then((data: any) => {
      if (data) {
        store.dispatch(appStore.changePicture(data));
      }
    })

    setInitializeApi(true);
    appInitialized({
      isLoading: false,
      hasAccess: true,
      hasError: false,
    });

    store.dispatch(authStore.setIsLoading(false));

  }

  return { initializeApp }

}
