import BaseController from './BaseController'
import {
  AdmissionType,
  AssignInbound,
  AssignOutbound,
  CitizenCodeResponse,
  CoursesResponse,
  CreateStudent,
  DuplicateInbound,
  DuplicateOutbound,
  DuplicateRecordInbound,
  DuplicateRecordOutbound,
  ErrorInbound,
  EthnicityCodeResponse,
  GenderCodeResponse,
  InfoInbound,
  InitiateInbound,
  Institution,
  InstitutionResponse,
  ObsoleteInbound,
  ObsoleteOutbound,
  RegistrationTypeCodeResponse,
  SearchOutbound,
  Student,
  StudentResponse,
  TermCodeResponse,
  TLOInbound,
  TLOOutbound,
  UpdateOutbound
} from './models/MitidModels'
import { SearchInboundExtended, SearchResult } from './models/WebModels'

class MitidController extends BaseController {
  getSearchResults = async (
    searchRequest: SearchOutbound
  ): Promise<SearchResult[]> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''
    const response = await this.useFetch(
      'POST',
      'mitid/search',
            `${apiHost}/${apiPath}/search`,
            searchRequest
    )
    if (response.status === 201 || response.status === 200) {
      const apiResponse: SearchInboundExtended = await response.json()
      return apiResponse.mitIdSearchResponse.map((entry) => {
        entry.mitidPerson.dobMatched =
                    searchRequest.inbound.dob === entry.mitidPerson.dob ? 'YES' : 'NO'
        if (entry.mitidPerson.fullDob) {
          entry.mitidPerson.dob = entry.mitidPerson.fullDob
        } else if (entry.mitidPerson.dob) {
          const dobArr = entry.mitidPerson.dob.match(/.{1,2}/g)
          entry.mitidPerson.dob = dobArr![0] + '/' + dobArr![1]
        }

        if (entry.mitidPerson.mitId === '(o)') {
          entry.mitidPerson.mitId = 'Obsolete'
        }

        entry.mitidPerson.ssn = 'NO'
        const matchSymbol = entry.mitidPerson.matchCode.substring(2, 3)
        if (['9'].includes(matchSymbol)) entry.mitidPerson.ssn = 'EXACT'
        else if (['8', '7', 'T'].includes(matchSymbol)) { entry.mitidPerson.ssn = 'SIMILAR' }

        if (entry.mitidPerson.obsoleteMitId === 'N') {
          entry.mitidPerson.obsoleteMitId = 'No'
        } else if (entry.mitidPerson.obsoleteMitId === 'Y') {
          entry.mitidPerson.obsoleteMitId = 'Yes'
        }

        return entry.mitidPerson
      })
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getInfo = async (mitid: string): Promise<InfoInbound> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''
    const response = await this.useFetch(
      'GET',
      'mitid/info',
            `${apiHost}/${apiPath}/info/${mitid}`
    )
    if (response.status === 201 || response.status === 200) {
      // return await response.json()
      const apiResponse: InfoInbound = await response.json()
      if (apiResponse.infoResponse.obsoleteMitId === 'Y') {
        apiResponse.infoResponse.obsoleteMitId = 'Yes'
      } else if (apiResponse.infoResponse.obsoleteMitId === 'N') {
        apiResponse.infoResponse.obsoleteMitId = 'No'
      }

      if (apiResponse.infoResponse.reservedMitId === 'Y') {
        apiResponse.infoResponse.reservedMitId = 'Yes'
      } else if (apiResponse.infoResponse.reservedMitId === 'N') {
        apiResponse.infoResponse.reservedMitId = 'No'
      }

      if (apiResponse.infoResponse.warehouseUpdated === 'Y') {
        apiResponse.infoResponse.warehouseUpdated = 'Yes'
      } else if (apiResponse.infoResponse.warehouseUpdated === 'N') {
        apiResponse.infoResponse.warehouseUpdated = 'No'
      }

      return apiResponse
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  createMITId = async (
    createRequest: AssignOutbound
  ): Promise<AssignInbound> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''
    const response = await this.useFetch(
      'POST',
      'mitid/create',
            `${apiHost}/${apiPath}/mitids`,
            createRequest
    )
    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  updateRecord = async (updateRequest: UpdateOutbound): Promise<any> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''
    const response = await this.useFetch(
      'POST',
      'mitid/update',
            `${apiHost}/${apiPath}/update`,
            updateRequest
    )
    if (response.status === 201 || response.status === 200) {
      return await Promise.resolve({})
      // return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  initialize = async (): Promise<InitiateInbound> => {
  
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''
    
    const response = await this.useFetch(
      'GET',
      'mitid/initiate',
            `${apiHost}/${apiPath}/initiate`
    )

    if (response.status === 201 || response.status === 200) {
      const apiResponse: InitiateInbound = await response.json()

      return apiResponse
    } else {
      // console.log('error', response.json())
      const error = await response.json()
      return await Promise.reject(error.message)
    }
  }

  getDuplicateReport = async (
    duplicateOutbound: DuplicateOutbound
  ): Promise<DuplicateInbound> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''
    const response = await this.useFetch(
      'POST',
      'mitid/duplicate',
            `${apiHost}/${apiPath}/duplicate`,
            duplicateOutbound
    )
    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getDuplicateRecord = async (
    duplicateRecordOutbound: DuplicateRecordOutbound
  ): Promise<DuplicateRecordInbound> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''
    const response = await this.useFetch(
      'POST',
      'mitid/duplicateWarehouseInfo',
            `${apiHost}/${apiPath}/duplicateWarehouseInfo`,
            duplicateRecordOutbound
    )
    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getStudentInfo = async (
    mitId: String
  ): Promise<StudentResponse> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''

    const response = await this.useFetch(
      'GET',
      'mitid/studentInfo',
            `${apiHost}/${apiPath}/studentInfo/${mitId}`
    )

    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getSchools = async (
    filter: String
  ): Promise<InstitutionResponse> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''

    const response = await this.useFetch(
      'GET',
      'mitid/schools',
            `${apiHost}/${apiPath}/quick-enroll/schools?filter=${filter}`
    )

    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getSchool = async (
    school: String
  ): Promise<Institution> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''

    const response = await this.useFetch(
      'GET',
      'mitid/school',
            `${apiHost}/${apiPath}/quick-enroll/school/${school}`
    )

    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getCollege = async (
    college: String
  ): Promise<Institution> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''

    const response = await this.useFetch(
      'GET',
      'mitid/school',
            `${apiHost}/${apiPath}/quick-enroll/college/${college}`
    )

    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getColleges = async (
    filter: String
  ): Promise<InstitutionResponse> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''

    const response = await this.useFetch(
      'GET',
      'mitid/colleges',
            `${apiHost}/${apiPath}/quick-enroll/colleges?filter=${filter}`
    )

    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getTermCodes = async (): Promise<TermCodeResponse> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''

    const response = await this.useFetch(
      'GET',
      'mitid/termCodes',
            `${apiHost}/${apiPath}/quick-enroll/termCodes`
    )

    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getCitizenCodes = async (): Promise<CitizenCodeResponse> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''

    const response = await this.useFetch(
      'GET',
      'mitid/citizenCodes',
            `${apiHost}/${apiPath}/quick-enroll/citizenCodes`
    )

    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getEthnicityCodes = async (): Promise<EthnicityCodeResponse> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''

    const response = await this.useFetch(
      'GET',
      'mitid/ethnicityCodes',
            `${apiHost}/${apiPath}/quick-enroll/ethnicityCodes`
    )

    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getGenders = async (): Promise<GenderCodeResponse> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''

    const response = await this.useFetch(
      'GET',
      'mitid/genderCodes',
            `${apiHost}/${apiPath}/quick-enroll/genders`
    )

    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getCourses = async (): Promise<CoursesResponse> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''

    const response = await this.useFetch(
      'GET',
      'mitid/courses',
            `${apiHost}/${apiPath}/quick-enroll/courses`
    )

    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getRegistrationTypes = async (): Promise<RegistrationTypeCodeResponse> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''

    const response = await this.useFetch(
      'GET',
      'mitid/registrationTypes',
            `${apiHost}/${apiPath}/quick-enroll/registrationTypes`
    )

    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  createStudentInfo = async (createRequest: CreateStudent): Promise<any> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''
    const response = await this.useFetch(
      'POST',
      'mitid/studentInfo/create',
            `${apiHost}/${apiPath}/studentInfo`,
            createRequest
    )
    if (response.status === 201 || response.status === 200) {
      return await Promise.resolve({})
      // return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  updateStudentInfo = async (createRequest: CreateStudent): Promise<any> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''
    const response = await this.useFetch(
      'PUT',
      'mitid/studentInfo/update',
            `${apiHost}/${apiPath}/studentInfo/${createRequest.student.identifiers.mitid}`,
            createRequest
    )
    if (response.status === 201 || response.status === 200) {
      return await Promise.resolve({})
      // return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getObsoleteReport = async (
    obsoleteOutbound: ObsoleteOutbound
  ): Promise<ObsoleteInbound> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''
    const response = await this.useFetch(
      'POST',
      'mitid/obsolete',
            `${apiHost}/${apiPath}/obsolete`,
            obsoleteOutbound
    )
    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  getTLOReport = async (tloOutbound: TLOOutbound): Promise<TLOInbound> => {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''
    const response = await this.useFetch(
      'POST',
      'mitid/tlo',
            `${apiHost}/${apiPath}/tlo`,
            tloOutbound
    )
    if (response.status === 201 || response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }

  async getAdmissionTypes (): Promise<AdmissionType[]> {
    const apiHost = this.apiHost
      ? this.apiHost
      : ''
    const apiPath = this.apiPath
      ? this.apiPath
      : ''
    const response = await this.useFetch(
      'GET',
      'mitid/admitTypes',
            `${apiHost}/${apiPath}/quick-enroll/admitTypes`
    )
    if (response.status === 200) {
      return await response.json()
    } else {
      const error: ErrorInbound = await response.json()
      return await Promise.reject(error)
    }
  }
}

export default MitidController
