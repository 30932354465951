import BaseController from './BaseController'
import { ProfileModel } from './models/ProfileModel'

class ProfileController extends BaseController {
  getProfile = async (): Promise<ProfileModel> => {
    const apiHost = this.apiHost ? this.apiHost : ''
    const digitalIdPath =this.apiPathDigitalId ? this.apiPathDigitalId : ''
    const profile = await this.useFetch('GET', 'mitid/getProfile', `${apiHost}/${digitalIdPath}/profile`)
    return profile.json()
  }

  getPicture = async (): Promise<any> => {
    const apiHost = this.apiHost ? this.apiHost : ''
    const digitalIdPath = this.apiPathDigitalId ? this.apiPathDigitalId : ''
    const picture = await this.useFetch('GET', 'mitid/getPicture', `${apiHost}/${digitalIdPath}/picture`)
    const data = await picture.blob()
    return URL.createObjectURL(data)
  }

  getPictureByKerb = async (kerb: string): Promise<any> => {
    const apiHost = this.apiHost ? this.apiHost : ''
    const digitalIdPath = this.apiPathDigitalId ? this.apiPathDigitalId : ''
    const picture = await this.useFetch('GET', 'mitid/getPictureForKerb', `${apiHost}/${digitalIdPath}/picture/${kerb}`)
    const data = await picture.blob()
    return URL.createObjectURL(data)
  }

  fetchPicture = async (baseUrl: string, url: string, query?: any): Promise<any> => {
    if (baseUrl === '/mock') {
      url = url + '.png'
    }

    url = baseUrl + url

    let queryString = ''

    if (query) {
      const esc = encodeURIComponent
      queryString = '?' + Object.keys(query)
        .map(k => esc(k) + '=' + esc(query[k]))
        .join('&')
    }

    try {
      const response = await this.useFetch('GET', 'mitid/getPicture', `${url}${queryString}`)

      if (response.status === 200) {
        const data = await response.blob()

        return URL.createObjectURL(data)
      } else {
        return null
      }
    } catch (e) {
      console.log('ERROR: ' + e)
    }
  }
}

export default ProfileController
